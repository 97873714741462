const formatNumber = (number) => {
    if (typeof number !== 'number' && typeof number !== 'string') {
      // console.log('Please provide a valid number or string.');
      return '';
    }
  
    // Convert number to string, in case a number is passed
    const numberStr = number.toFixed(0).toString();
  
    // Use a regular expression to insert commas every 3 digits
    return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

module.exports = {
  formatNumber
}