import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    LinearProgress,
    Alert,
    Paper,
    Divider,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material';
import {
    SignalCellularAlt as SignalIcon,
    Memory as MemoryIcon,
    Speed as SpeedIcon,
    Error as ErrorIcon,
    Refresh as RefreshIcon,
    Check as CheckIcon,
    Cancel as CancelIcon
} from '@mui/icons-material';

const SocketMonitor = () => {
    const [refreshing, setRefreshing] = useState(false);
    const [metrics, setMetrics] = useState({
        loading: true,
        timestamp: null,
        system: {
            uptime: { formatted: { hours: 0, minutes: 0, seconds: 0 } },
            memory: {
                heap: { total: 0, used: 0, usage: "0" },
                system: { rss: 0 }
            },
            cpu: { percentage: "0" }
        },
        socket: {
            server: {
                status: 'disconnected',
                connections: { active: 0, unique: 0 },
                performance: { latency: "0", errorRate: "0" }
            },
            monitor: {
                updates: { total: 0, failed: 0 }
            }
        },
        health: {
            score: "0",
            issues: []
        }
    });

    const fetchMetrics = async () => {
        try {
            setRefreshing(true);
            const response = await fetch('https://app.foxi.tips/api/admin/socket/health', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
                }
            });
            const data = await response.json();
            setMetrics({
                ...data.data,
                loading: false
            });
        } catch (error) {
            console.error('Error fetching socket metrics:', error);
        } finally {
            setRefreshing(false);
        }
    };

    useEffect(() => {
        fetchMetrics();
        const interval = setInterval(fetchMetrics, 5000);
        return () => clearInterval(interval);
    }, []);

    const getHealthColor = (score) => {
        if (score >= 80) return 'success.main';
        if (score >= 60) return 'warning.main';
        return 'error.main';
    };

    const getMemoryColor = (percentage) => {
        if (percentage > 80) return 'error';
        if (percentage > 60) return 'warning';
        return 'success';
    };

    return (
        <Box sx={{ p: 3, height: '100vh', bgcolor: 'gainsboro' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h5" component="h2">
                    Socket Monitor
                </Typography>
                <Tooltip title="Refresh Metrics">
                    <IconButton onClick={fetchMetrics} disabled={refreshing}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>

            <Grid container spacing={3} mb={3}>
                {/* Connection Status */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Connection Status
                            </Typography>
                            <Box display="flex" alignItems="center">
                                {metrics.socket.server.status === 'running' ? (
                                    <CheckIcon color="success" />
                                ) : (
                                    <CancelIcon color="error" />
                                )}
                                <Typography variant="h6" component="div" ml={1}>
                                    {metrics.socket.server.status}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Active Connections */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Active Connections
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <SignalIcon />
                                <Typography variant="h6" component="div" ml={1}>
                                    {metrics.socket.server.connections.active}
                                </Typography>
                            </Box>
                            <Typography variant="caption" color="textSecondary">
                                Unique Users: {metrics.socket.server.connections.unique}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Error Rate */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Error Rate
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <ErrorIcon />
                                <Typography variant="h6" component="div" ml={1}>
                                    {metrics.socket.server.performance.errorRate}%
                                </Typography>
                            </Box>
                            <Typography variant="caption" color="textSecondary">
                                Failed Updates: {metrics.socket.monitor.updates.failed}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Health Score */}
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Health Score
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress
                                        variant="determinate"
                                        value={parseFloat(metrics.health.score)}
                                        sx={{ color: getHealthColor(parseFloat(metrics.health.score)) }}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="caption" component="div">
                                            {metrics.health.score}%
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* System Stats */}
            <Grid container spacing={3} mb={3}>
                {/* Memory Usage */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <MemoryIcon sx={{ mr: 1 }} />
                            <Typography variant="h6">Memory Usage</Typography>
                        </Box>
                        <Box mb={1}>
                            <LinearProgress 
                                variant="determinate" 
                                value={parseFloat(metrics.system.memory.heap.usage)}
                                color={getMemoryColor(parseFloat(metrics.system.memory.heap.usage))}
                                sx={{ height: 10, borderRadius: 5 }}
                            />
                        </Box>
                        <Typography variant="body2" color="textSecondary">
                            {`${metrics.system.memory.heap.used}MB / ${metrics.system.memory.heap.total}MB (${metrics.system.memory.heap.usage}%)`}
                        </Typography>
                    </Paper>
                </Grid>

                {/* CPU Usage */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 2 }}>
                        <Box display="flex" alignItems="center" mb={1}>
                            <SpeedIcon sx={{ mr: 1 }} />
                            <Typography variant="h6">CPU Usage</Typography>
                        </Box>
                        <Typography variant="h4" component="div">
                            {metrics.system.cpu.percentage}%
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            {/* Issues/Errors */}
            {metrics.health.issues.length > 0 && (
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <ErrorIcon color="error" sx={{ mr: 1 }} />
                        <Typography variant="h6">Active Issues</Typography>
                    </Box>
                    {metrics.health.issues.map((error, index) => (
                        <Alert severity="error" key={index} sx={{ mb: 1 }}>
                            {error}
                        </Alert>
                    ))}
                </Paper>
            )}

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between">
                <Typography variant="caption" color="textSecondary">
                    Last updated: {new Date(metrics.timestamp).toLocaleTimeString()}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    Uptime: {metrics.system.uptime.formatted.hours}h {metrics.system.uptime.formatted.minutes}m {metrics.system.uptime.formatted.seconds}s
                </Typography>
            </Box>
        </Box>
    );
};

export default SocketMonitor;