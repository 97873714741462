import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, Typography, IconButton, LinearProgress, List, Grid,
  ListItem, ListItemIcon, ListItemText, Avatar 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { userApi } from '../../services/api';
import { formatNumber } from '../../utils/Formatters';
import Loading from '../Loading/Loading';

// League icons import
const leagueIcons = {
  1: require('../../assets/images/leagues/1.png'),
  2: require('../../assets/images/leagues/2.png'),
  3: require('../../assets/images/leagues/3.png'),
  4: require('../../assets/images/leagues/4.png'),
  5: require('../../assets/images/leagues/5.png'),
  6: require('../../assets/images/leagues/6.png'),
  7: require('../../assets/images/leagues/7.png'),
  8: require('../../assets/images/leagues/8.png'),
  9: require('../../assets/images/leagues/9.png'),
  10: require('../../assets/images/leagues/10.png'),
};

const PopupBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  maxWidth: '100%',
  maxHeight: '85vh',
  overflowY: 'auto',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  // background: `url(${backgroundImg}) repeat-x`,
  // backgroundSize: 'cover',
  padding: theme.spacing(3),
  color: 'white',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7),
  borderTop: '5px solid orange',
  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.5s ease-in-out',
  transform: 'translateY(100%)',
  zIndex: 999,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(3.5),
  marginBottom: theme.spacing(1),
  paddingLeft: 0,
}));

const LeagueItem = React.memo(({ league, index, leagueIcon, shortenNumber }) => (
  <StyledListItem>
    <ListItemIcon>
      <Avatar
        alt={league.name}
        src={leagueIcon}
        sx={{ width: 55, height: 55, ml: 1 }}
      >
        {index + 1}
      </Avatar>
    </ListItemIcon>
    <ListItemText
      primary={league.name}
      secondary={
        <React.Fragment>
          <Box component="span" sx={{ 
            display: 'inline-flex', 
            alignItems: 'center', 
            fontFamily: 'sora, sans-serif', 
            fontWeight: 'bold', 
            color: 'gold', 
            mr: 0.5 
          }}>
            <MonetizationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
            {shortenNumber(league.pointsRequired)}
          </Box>
          <Typography 
            component="span" 
            variant="body1" 
            fontFamily="sora, sans-serif" 
            color="white" 
            sx={{ ml: 7, position: 'relative', bottom: '3px' }}
          >
            Budget: {shortenNumber(league.allowance)}
          </Typography>
        </React.Fragment>
      }
      primaryTypographyProps={{ fontWeight: 'bold' }}
      secondaryTypographyProps={{ 
        component: 'div', 
        display: 'flex', 
        alignItems: 'flex-start', 
        mt: 0.75 
      }}
    />
    <Typography variant="h4" fontFamily="sora, sans-serif" fontWeight="bold">
      {index + 1}
    </Typography>
  </StyledListItem>
));

const LeaguesPopup = ({ 
  open, 
  onClose, 
  userProfileInfo,
  showSnackbar
}) => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLeagues = useCallback(async () => {
    if (!open) return;

    setLoading(true);
    try {
      const response = await userApi.getLeagues();
      if (response.data.success) {
        setLeagues(response.data.leagues);
      } else {
        throw new Error('Failed to fetch leagues data');
      }
    } catch (error) {
      console.error("Error fetching leagues:", error);
      showSnackbar("Failed to fetch leagues data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [open]);

  useEffect(() => {
    fetchLeagues();
  }, [fetchLeagues]);

  const shortenNumber = useCallback((number) => {
    if (number >= 10000) {
      return (number / 1000).toFixed(1) + 'K';
    }
    return formatNumber(number);
  }, []);

  // Calculate progress using new schema
  const progressValue = useMemo(() => {
    const currentPoints = userProfileInfo?.treasury?.points || 0;
    const nextRequired = userProfileInfo?.league?.nextRequired || 0;
    return Math.round((currentPoints / (nextRequired + currentPoints)) * 100);
  }, [userProfileInfo]);

  const currentLeagueIcon = useMemo(() => 
    leagueIcons[userProfileInfo?.league?.index || 1],
    [userProfileInfo]
  );

  return (
    <PopupBox sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <IconButton
        sx={{ position: 'absolute', top: 16, right: 16, color: 'white' }}
        onClick={onClose}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Avatar
          alt="League"
          src={currentLeagueIcon}
          sx={{ width: 170, height: 170, mb: 1 }}
          loading="lazy"
        />
        <Typography 
          variant="h5" 
          component="h1" 
          fontFamily="Poppins-Bold, sans-serif" 
          fontWeight="bold" 
          textAlign="center" 
          mb={2}
        >
          <span style={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)', 
            padding: '2px 6px 2px 9px', 
            borderRadius: '15px' 
          }}> 
            lvl {userProfileInfo?.league?.index || 0} 
          </span>
          &nbsp; {userProfileInfo?.league?.current || 'No league'}
        </Typography>
        <Grid 
          display="flex" 
          flexDirection="row" 
          justifyContent="space-between" 
          width="100%"
        >
          <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight="bold">
            Next lvl: {(userProfileInfo?.league?.index || 0) + 1}
          </Typography>
          <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight="bold">
            {formatNumber(userProfileInfo?.treasury?.points || 0)} / {
              formatNumber((userProfileInfo?.league?.nextRequired || 0) + 
                (userProfileInfo?.treasury?.points || 0))
            }
          </Typography>
        </Grid>
        <Box width="100%" mb={1}>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4CAF50',
              },
            }}
          />
        </Box>
      </Box>

      <List sx={{ width: '95vw', position: 'relative', right: '11px' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <Loading loading={loading} customStyle={{ left: '38vw', top: '10vh' }} />
          </Box>
        ) : leagues.length > 0 ? (
          leagues.map((league, index) => (
            <LeagueItem
              key={league.name}
              league={league}
              index={index}
              leagueIcon={leagueIcons[index + 1]}
              shortenNumber={shortenNumber}
            />
          ))
        ) : (
          <Typography variant="body1" textAlign="center">
            No leagues data available
          </Typography>
        )}
      </List>
    </PopupBox>
  );
};

LeaguesPopup.propTypes = {
  userProfileInfo: PropTypes.shape({
    league: PropTypes.shape({
      index: PropTypes.number,
      current: PropTypes.string
    }),
    treasury: PropTypes.shape({
      points: PropTypes.number
    })
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  ENDPOINT: PropTypes.string.isRequired
};

export default React.memo(LeaguesPopup);