// Confetti.jsx
import React, { memo, useMemo } from 'react';
import Confetti from 'react-confetti-boom';

const ConfettiComponent = memo(function ConfettiComponent({ 
  openConfetti = false, 
  setOpenConfetti = () => {},
  x = 0.5, 
  y = 0.85,
  particleCount = 25,
  shapeSize = 15,
  spreadDeg = 90,
  effectInterval = 500
}) {
  // Memoize colors array to prevent recreation
  const colors = useMemo(() => 
    ['#05b5f3', '#ff49d0', '#ffd384', '#fff9b0', '#3498db'], 
    []
  );

  // Only render when actually needed
  if (!openConfetti) return null;

  // Disable openConfetti after 7 seconds
  setTimeout(() => setOpenConfetti(false), 7000);

  return (
    <span style={{ 
      zIndex: 99999,
      position: 'fixed', // Use fixed positioning
      pointerEvents: 'none', // Prevent interaction with particles
      willChange: 'transform', // Optimize for animations
      isolation: 'isolate' // Create new stacking context
    }}>
      <Confetti
        mode='boom'
        x={x}
        y={y}
        particleCount={particleCount} // Consider reducing for better performance
        deg={270}
        shapeSize={shapeSize}
        spreadDeg={spreadDeg}
        effectInterval={effectInterval}
        effectCount={1}
        colors={colors}
        // Add performance optimizations
        style={{
          willChange: 'transform',
          transform: 'translate3d(0,0,0)', // Force GPU acceleration
          backfaceVisibility: 'hidden'
        }}
      />
    </span>
  );
});

export default ConfettiComponent;