import { useEffect, useState } from 'react';
import FoxiCharacter from '../assets/images/home-elements/foxi-character.png';
import DiamondPng from '../assets/images/home-elements/diamond.png';
import CheckInPng from '../assets/images/home-elements/check-in.png';
import ReceiveTipIcon from '../assets/images/home-elements/receive-tip.png';
import SendTipIcon from '../assets/images/home-elements/send-tip.png';
import BG3 from '../assets/images/home-elements/bg-3.jpg';
import BG2 from '../assets/images/home-elements/bg-2.png';
import BG1 from '../assets/images/bg.png';
import FoxiQRCode from '../assets/images/qr-code/foxi-character-qr-code.png';

const IMAGES_TO_PRELOAD = [
  { name: 'Foxi Character', path: FoxiCharacter },
  { name: 'Diamond', path: DiamondPng },
  { name: 'Check In', path: CheckInPng },
  { name: 'Receive Tip Icon', path: ReceiveTipIcon },
  { name: 'Send Tip Icon', path: SendTipIcon },
  { name: 'Background 3', path: BG3 },
  { name: 'Background 2', path: BG2 },
  { name: 'Background 1', path: BG1 },
  { name: 'QR Code Foxi', path: FoxiQRCode },
];

const useImagePreloader = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let loadedCount = 0;
    const totalImages = IMAGES_TO_PRELOAD.length;

    const updateProgress = () => {
      loadedCount++;
      const currentProgress = Math.round((loadedCount / totalImages) * 100);
      setProgress(currentProgress);
      // console.log(`Loading: ${currentProgress}%`);

      if (loadedCount === totalImages) {
        setImagesLoaded(true);
      }
    };

    IMAGES_TO_PRELOAD.forEach(({ name, path }) => {
      const img = new Image();
      
      img.onload = updateProgress;

      img.onerror = () => {
        console.error(`Failed to load: ${name}`);
        updateProgress();
      };

      img.src = path;
    });

  }, []);

  return { imagesLoaded, progress };
};

export default useImagePreloader;