// ParticleSystem.jsx
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const particleAnimation = keyframes`
  0% { 
    transform: translate3d(0, 0, 0);
    opacity: var(--initial-opacity); 
  }
  100% { 
    transform: translate3d(var(--tx), var(--ty), 0);
    opacity: 0; 
  }
`;

const Particle = styled('div')({
  position: 'absolute',
  animation: `${particleAnimation} 2s ease-out infinite`,
  transform: 'translate3d(0, 0, 0)',
  willChange: 'transform, opacity',
  backfaceVisibility: 'hidden',
});

const ParticleSystem = React.memo(({ 
  count = 15,
  minSize = 30,
  maxSize = 33,
  spread = 300,
  minDuration = 3,
  maxDuration = 5,
  minDelay = 0,
  maxDelay = 2,
  className,
  style 
}) => {
  const starImage = useMemo(() => 
    `url(https://img.icons8.com/pulsar-gradient/48/star.png)`, 
    []
  );

  const particles = useMemo(() => {
    return Array.from({ length: count }, (_, i) => ({
      id: i,
      size: Math.random() * (maxSize - minSize) + minSize,
      tx: (Math.random() - 0.5) * spread,
      ty: (Math.random() - 0.5) * spread,
      delay: Math.random() * (maxDelay - minDelay) + minDelay,
      duration: Math.random() * (maxDuration - minDuration) + minDuration,
      opacity: 0.5 + Math.random() * 0.5,
    }));
  }, [count, minSize, maxSize, spread, minDuration, maxDuration, minDelay, maxDelay]);

  const renderParticle = useCallback((particle) => {
    const style = {
      position: 'absolute',
      width: `${particle.size}px`,
      height: `${particle.size}px`,
      background: `${starImage} no-repeat center center`,
      backgroundSize: 'cover',
      opacity: particle.opacity,
      filter: 'drop-shadow(0 0 5px gold)',
      transform: 'translate3d(0, 0, 0)',
      willChange: 'transform, opacity',
      animationDelay: `${particle.delay}s`,
      animationDuration: `${particle.duration}s`,
      '--tx': `${particle.tx}px`,
      '--ty': `${particle.ty}px`,
    };

    return <Particle key={particle.id} style={style} />;
  }, [starImage]);

  return (
    <Box 
      className={className}
      sx={{ 
        position: 'absolute',
        width: '100%',
        height: '100%',
        // contain: 'strict',
        pointerEvents: 'none',
        ...style
      }}
    >
      {particles.map(renderParticle)}
    </Box>
  );
});

ParticleSystem.propTypes = {
  count: PropTypes.number,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  spread: PropTypes.number,
  minDuration: PropTypes.number,
  maxDuration: PropTypes.number,
  minDelay: PropTypes.number,
  maxDelay: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ParticleSystem;