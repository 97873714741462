import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, Typography, IconButton, Avatar, ToggleButton, ToggleButtonGroup,
  List, ListItem, ListItemText 
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { userApi } from '../../services/api';
import { formatNumber } from '../../utils/Formatters';
import Loading from '../Loading/Loading';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: '93vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  // background: `url(${PopupBackground}) repeat-x`,
  // backgroundSize: 'cover',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7),
  borderTop: '5px solid orange',
  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: 'white',
  zIndex: 999,
}));

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: '4px 0px',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '90vw',
  height: '60px',
  borderRadius: '15px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  margin: '8px 0px',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(6),
  justifyContent: 'center',
  borderRadius: '20px',
  '& .MuiToggleButtonGroup-grouped': {
    // margin: 5,
    border: '2px solid rgba(255, 255, 255, 0.5)',
    // borderRadius: '20px !important', // Important to override MUI styles
    width: '35vw',
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 'bold',
    color: 'white',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: '#5529ec',
      color: 'white',
      boxShadow: `
        inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
        inset 0 -4px 4px 1px #c5b3ff,
        inset 0 0 0px 8px rgba(255, 255, 255, 0.2),
        0 1px 4px 1px rgba(56, 41, 236, 0.2),
        0 1px 3px 1px #5529ec
      `,
      '&:hover': {
        backgroundColor: '#6339ec',
      }
    }
  }
}));

const StyledToggleButton = styled(ToggleButton)(({ active, side }) => ({
  textTransform: 'none',
  height: '45px',
  fontSize: '15px',
  position: 'relative',
  borderRadius: side === 'left' ? '15px 0 0 15px' : '0 15px 15px 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: active ? 
      'linear-gradient(to bottom, #e9f0ff, transparent)' : 
      'none',
    opacity: 0.75,
  }
}));

// Memoized Activity Item Component
const ActivityItem = React.memo(({ activity, activeTab }) => (
  <StyledListItem>
    <Avatar
      alt={activity.firstname}
      src={`${process.env.REACT_APP_API_URL}/public/users/avatars/${activity.userId}.jpg`}
      sx={{ width: 45, height: 45, ml: 1 }}
      loading="lazy"
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
      <ListItemText 
        primary={activity.username} 
        secondary={activity.firstname}
        primaryTypographyProps={{ fontFamily: 'Sora', fontWeight: 'bold', fontSize: '18px' }}
        secondaryTypographyProps={{ fontFamily: 'Sora', fontWeight: '300', fontSize: '15px', color: 'white' }}
      />
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
      <ListItemText 
        primary={`${activeTab === 'sent' ? '-' : '+'}${formatNumber(activity.amount)}`} 
        sx={{ position: 'absolute', right: '15px', top: '15px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}}
      />
    </Box>
  </StyledListItem>
));

const HistoryPopup = ({ userId, activeTab, setActiveTab, open, onClose, showSnackbar }) => {
  const [historyActivity, setHistoryActivity] = useState(null);
  const [totalInteractionPoints, setTotalInteractionPoints] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchHistoryActivity = useCallback(async () => {
    if (!userId || !open) return;

    setLoading(true);
    try {
      const response = await userApi.getTipHistory(userId);
      const tipHistory = response.data.tipHistory;
      const fieldObj = activeTab === 'received' ? 'from' : 'to';
      
      const mappedResponse = Object.entries(tipHistory[activeTab] || {})
        .map(([key, value]) => ({ 
          userId: value[fieldObj].userId,
          username: value[fieldObj].username,
          firstname: value[fieldObj].firstname, 
          amount: value.amount 
        }))
        .reverse();

      setHistoryActivity(mappedResponse);

      const totalPoints = mappedResponse.reduce(
        (total, activity) => total + Number(parseFloat(activity.amount).toFixed(0)), 
        0
      );
      setTotalInteractionPoints(totalPoints);
    } catch (error) {
      console.error('Error fetching tip history:', error);
      showSnackbar('Failed to fetch tip history. Please try again.', 'error');
      setHistoryActivity(null);
    } finally {
      setLoading(false);
    }
  }, [userId, activeTab, open, showSnackbar]);

  useEffect(() => {
    fetchHistoryActivity();
  }, [fetchHistoryActivity]);

  const handleTabChange = useCallback((event, newValue) => {
    if (newValue !== null) {
      setActiveTab(newValue);
    }
  }, [setActiveTab]);

  const listStyles = useMemo(() => ({
    maxHeight: '450px', 
    overflow: 'auto', 
    '&::-webkit-scrollbar': { width: '5px', height: '5px' }, 
    '&::-webkit-scrollbar-track': { background: 'transparent' }, 
    '&::-webkit-scrollbar-thumb': { background: 'rgba(255, 255, 255, 0.2)', borderRadius: '5px' }, 
    '& .MuiListItem-root:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }, 
    '& .MuiListItemIcon-root': { minWidth: '45px' }
  }), []);

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right='3vw' top='1vh'>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" htmlColor="white" />
        </IconButton>
      </Box>
      
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" fontFamily="Poppins-Bold" fontSize="20px" sx={{ width: 'max-content', position: 'relative', left: '6vw', top: '5vh' }}>
          History
        </Typography>
        <Typography variant="body1" fontFamily="sora, sans-serif" sx={{ alignSelf: "center", mt: 8 }}>
          Total {activeTab === 'received' ? 'Points Received' : 'Amount You Tipped'}
        </Typography>
        <Typography variant="h4" fontFamily="Poppins-Bold" sx={{ alignSelf: "center", mb: 2 }}>
          {formatNumber(totalInteractionPoints)}
        </Typography>
      </Box>

      <StyledToggleButtonGroup
        value={activeTab}
        exclusive
        onChange={handleTabChange}
        aria-label="tip history type"
      >
        <StyledToggleButton 
          value="received" 
          aria-label="received tips"
          active={activeTab === 'received'}
          side="left"
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1
          }}>
            <img 
              src="https://img.icons8.com/fluency/48/receive-euro.png"
              alt="receive"
              style={{ 
                width: '20px', 
                height: '20px',
                filter: activeTab === 'received' ? 'brightness(1.2)' : 'brightness(0.9)'
              }} 
            />
            Received
          </Box>
        </StyledToggleButton>
        <StyledToggleButton 
          value="sent" 
          aria-label="sent tips"
          active={activeTab === 'sent'}
          side="right"
        >
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1
          }}>
            <img 
              src="https://img.icons8.com/fluency/48/sent.png" 
              alt="sent"
              style={{ 
                width: '20px', 
                height: '20px',
                filter: activeTab === 'sent' ? 'brightness(1.2)' : 'brightness(0.9)',
              }} 
            />
            Sent
          </Box>
        </StyledToggleButton>
      </StyledToggleButtonGroup>

      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        {loading ? (
          <Loading loading={true} customStyle={{ top: '48vh', left: '38vw' }}/>
        ) : historyActivity && historyActivity.length > 0 ? (
          <StyledList className="custom-scrollbar" dense sx={listStyles}>
            {historyActivity.map((activity, index) => (
              <ActivityItem 
                key={`${index}-${activity.userId}`}
                activity={activity}
                activeTab={activeTab}
              />
            ))}
          </StyledList>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="275px">
            <Typography variant="h6" fontFamily="Poppins-Bold">
              No activity yet
            </Typography>
          </Box>
        )}
      </Box>
    </PopupContainer>
  );
};

HistoryPopup.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

export default React.memo(HistoryPopup);