import React, { memo } from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

// Flash animation with shadow pulsing
const flashAnimation = keyframes`
  0% {
    opacity: 0.85;
    filter: drop-shadow(0 0 5px #ff0000);
  }
  50% {
    opacity: 1;
    filter: drop-shadow(0 0 9px #ff0000) drop-shadow(0 0 3px #ff0000);
    transform: scale(1.1);
  }
  100% {
    opacity: 0.85;
    filter: drop-shadow(0 0 5px #ff0000);
  }
`;

const AlertBadge = memo(({ 
  customStyle,
  size = 20,
  position = 'absolute',
  top = -8,
  right = -8,
  pulseSpeed = 1.5,
  children 
}) => {
  return (
    <Box
      sx={{
        position: position,
        top: top,
        right: right,
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: '#ff0000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: size * 0.75,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold, sans-serif',
        animation: `${flashAnimation} ${pulseSpeed}s ease-in-out infinite`,
        transform: 'translate3d(0,0,0)', // Force GPU acceleration
        willChange: 'transform, opacity, filter',
        zIndex: 2,
        ...customStyle
      }}
    >
      {children}
    </Box>
  );
});

AlertBadge.displayName = 'AlertBadge';

export default AlertBadge;