import React, { useState, useRef, useCallback } from 'react'
import { motion, useAnimation } from 'framer-motion'
import './SpinButton.css'

export default function SpinButton({ startSpin, disabled }) {
  const [isDragging, setIsDragging] = useState(false)
  const [isFullyDragged, setIsFullyDragged] = useState(false)
  const constraintsRef = useRef(null)
  const controls = useAnimation()
  const spinTriggeredRef = useRef(false)

  const handleDrag = useCallback((event, info) => {
    if (info.point.x > 100 && !spinTriggeredRef.current && !disabled) {
      setIsFullyDragged(true)
      spinTriggeredRef.current = true
      startSpin()
    } else if (info.point.x <= 100) {
      setIsFullyDragged(false)
    }
  }, [startSpin, disabled])

  const handleDragEnd = useCallback(() => {
    setIsDragging(false)
    controls.start({ x: 0 })
    setIsFullyDragged(false)
    spinTriggeredRef.current = false
  }, [controls])

  return (
    <div className="spin-button-container">
      <div ref={constraintsRef} className="spin-button">
        <motion.div
          className="spin-button-circle"
          drag="x"
          dragConstraints={{ left: 0, right: 100 }}
          dragElastic={0.1}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={handleDragEnd}
          animate={controls}
          whileDrag={{ scale: 1.05 }}
          onDrag={handleDrag}
          style={{ cursor: disabled ? 'not-allowed' : 'grab' }}
        >
          <div className="spin-button-inner-circle">
            <span className="spin-button-number">1</span>
            <img src="https://img.icons8.com/color/48/diamond.png" alt="Diamond" className="spin-button-icon" />
          </div>
        </motion.div>
        <motion.div 
          className="spin-button-text"
          animate={{ opacity: isFullyDragged ? 0 : 1 }}
         >
          Drag to Spin
        </motion.div>
        <motion.div 
          className="drag-text"
          animate={{ opacity: isFullyDragged ? 1 : 0 }}
        >
          Done!
        </motion.div>
      </div>
    </div>
  )
}