import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  ThemeProvider, 
  createTheme 
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import MaintenanceBackground from '../assets/images/under-maintenance/bg.png';
import FoxiCharacter from '../assets/images/under-maintenance/fox.png';
import YellowBarImage from '../assets/images/under-maintenance/yellow-bar.png';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

export default function UnderMaintenance() {
  const YellowBar = ({ x, y, rotation, width = 100, height = 20, animationDuration = 10 }) => {
    const barStyle = {
      position: 'absolute',
      left: `${x}vw`,
      bottom: `${y}vh`,
      width: `${width}vw`,
      height: `${height}vh`,
      backgroundColor: '#FFD700',
      transform: `rotate(${rotation}deg)`,
      boxShadow: '0 0 10px rgba(255, 215, 0, 0.7)',
      zIndex: 99,
      overflow: 'hidden',
    };
  
    const stripeStyle = {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'repeating-linear-gradient(45deg, #000, #000 10px, #FFD700 10px, #FFD700 20px)',
      opacity: 0.2,
      animation: `moveStripes ${animationDuration}s linear infinite`,
    };
  
    const keyframes = `
      @keyframes moveStripes {
        0% {
          background-position: 0px;
        }
        50% {
          background-position-x: 1000px;
        }
        100% {
          background-position-x: 0px;
        }
      }
    `;
  
    return (
      <>
        <style>{keyframes}</style>
        <div style={barStyle}>
          <div style={stripeStyle}></div>
        </div>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          backgroundImage: `url('${MaintenanceBackground}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* Fox in center */}
        <Box
          sx={{
            position: 'absolute',
            top: '10vh',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            width: '380px',
            height: '380px',
            backgroundImage: `url('${FoxiCharacter}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 1,
          }}
        />

        {/* Yellow Bar Image */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '25vh',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            width: '100vw',
            height: '23vh',
            backgroundImage: `url('${YellowBarImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 9,
          }}
        />

        {/* Animated Yellow Bars */}
        <YellowBar x={0} y={0} rotation={0} width={100} height={3} animationDuration={20} />
          
        <Box
          sx={{
            width: '100%',
            background: 'linear-gradient(0deg, #000000f7 16%, #000000d4)',
            backdropFilter: 'blur(6px)',
            position: 'sticky',
            bottom: 0,
            borderTopLeftRadius: '25px',
            borderTopRightRadius: '25px',
            zIndex: 9
          }}
        >
          <Container maxWidth="lg">
            <Box 
              sx={{
                py: 4,
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: { xs: 'center', md: 'flex-start' },
                  mb: { xs: 2, md: 0 },
                }}
              >
                <ConstructionIcon 
                  sx={{ 
                    fontSize: 48, 
                    fontWeight: 'bold',
                    fontFamily: 'sora, sans-serif',
                    color: 'primary.main', 
                    mb: 1
                  }} 
                />
                <Typography variant="h5" component="h1" fontWeight="bolder" fontFamily="sora, sans-serif" color="primary" gutterBottom>
                   System Under Maintenance
                </Typography>
                <Typography variant="body1" fontWeight="500" fontFamily="sora, sans-serif" color="primary" sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                  For any questions, feel free to reach out through our TG group
                </Typography>
              </Box>
              <Button 
                variant="outlined" 
                color="primary" 
                href="https://t.me/FoxiTips" 
                target="_blank" 
                rel="noopener noreferrer"
                sx={{ 
                  mb: 2,
                  borderColor: 'primary.main',
                  borderRadius: '10px',
                  fontFamily: 'sora, sans-serif',
                  fontSize: '17px',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                  }
                }}
              >
                Join Community
              </Button>
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}