import React from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import QRCodeImg from '../assets/images/qr-code/qr-code-2.png';
import FoxiCharacter from '../assets/images/qr-code/foxi-character-qr-code.png';
import BookIcon from '@mui/icons-material/Book';

// Import icons
import Telegram from '../assets/images/icons/telegram.png';
import X from '../assets/images/icons/x.png';
import Youtube from '../assets/images/icons/youtube.png';
import GradientBoxShadow from './GradientBoxShadow/GradientBoxShadow';


// Import Sora font
const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  background: 'linear-gradient(180deg, #1a0933 0%, #7b238e 50%, #1a0933 100%)',
  color: 'white',
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden'
}));

const ContentContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 20px',
  flex: 1,
  zIndex: 1
});

const QRContainer = styled(Box)({
  width: '33vh',
  height: '33vh',
  backgroundColor: 'white',
  borderRadius: '32px',
  padding: '15px',
  marginTop: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const WhitepaperButton = styled(Button)(({ theme }) => ({
    width: '70%',
    textTransform: 'none',
    fontFamily: 'Sora',
    fontSize: '15px',
    borderRadius: '10rem',
    color: 'white',
    marginBottom: theme.spacing(1),
    padding: '8px 0px',
    backgroundColor: '#b829ec33',
    backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
    boxShadow: 'inset 0 -2px 4px 1px rgb(219 38 163 / 0%), inset 0 -4px 4px 1px #b93fff00, inset 0 0 0px 8px rgb(255 255 255 / 20%), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5500ff82',
    textShadow: '0 1px 1px #3829ec',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      width: '85%',
    },
    '&:active': {
      borderColor: '#2e23d6',
      width: '85%',
      backgroundColor: '#2e23d6',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '1px',
      left: '50%',
      transform: 'translateX(-50%)',
      borderRadius: '50px',
      width: '80%',
      height: '40%',
      backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
      opacity: 0.75,
    },
    '&:focus': {
      outline: 'none',
    }
  }));

const SocialContainer = styled(Box)({
  display: 'flex',
  gap: '5px',
  marginBottom: '8px'
});

const SocialButton = styled(Box)({
  width: '40px',
  height: '40px',
  backgroundColor: '#ffffff4f',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'transform 0.2s',
  boxShadow: 'inset 0px 0px 0px 4px rgb(0 0 0 / 25%), inset 0 -4px 4px 1px #b93fff00, inset 0 0 0px 8px rgb(255 255 255 / 37%), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5500ff82',
  '&:hover': {
    transform: 'scale(1.1)',
  }
});

const Divider = styled(Box)({
  width: '50vw',
  height: '3px',
  backgroundColor: 'rgb(255 255 255 / 25%)',
  marginBottom: '8px'
});

const StyledLinkButton = styled(Link)({
  width: '100%',
  height: '80%',
});

export default function DesktopPage() {
  return (
    <PageContainer>
      <ContentContainer>
        <Typography 
          variant="h4" 
          sx={{ 
            fontFamily: 'Poppins-Bold, sans-serif',
            fontWeight: 600,
            fontSize: '28px',
            marginBottom: '7px',
            marginTop: '16px'
          }}
        >
          Welcome to Foxi.tips
        </Typography>
        
        <Typography 
          sx={{ 
            fontFamily: 'Sora, sans-serif',
            fontSize: '17px',
            lineHeight: 1.5,
            maxWidth: '90%',
            marginBottom: '14px',
            fontWeight: 400
          }}
        >
          For a better experience, Please scan this QR code and Play it on mobile
        </Typography>

        <QRContainer>
          <img 
            src={QRCodeImg}
            alt="QR code that links to Foxi Tips Telegram mobile application"
            style={{ width: '100%', height: '100%' }}
          />
        </QRContainer>

        <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%', position: 'absolute', bottom: '0px' }}>
            <Box sx={{ flex: 1, position: 'relative', width: '100%', minHeight: '50px' }}>
                <img
                    src={FoxiCharacter}
                    alt="A sophisticated cartoon fox character wearing a black top hat, bow tie, and formal attire. The fox has bright green eyes and a friendly expression, rendered in a high-quality 3D style against a transparent background"
                    style={{ 
                    width: '18vh',
                    position: 'absolute',
                    bottom: '0px',
                    left: '35vw',
                    transform: 'translateX(-50%)',
                    }}
                />
             </Box>

            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <WhitepaperButton variant="contained">
                    <BookIcon sx={{ width: '24px', height: '24px' }} />
                    Read Foxi whitepaper
                </WhitepaperButton>

                <Divider />

                {/* <SocialContainer sx={{ position: 'absolute', bottom: '10vh', right: '13vw' }}> */}
                <SocialContainer>
                    <SocialButton>
                        <StyledLinkButton href="https://t.me/foxi_tips" target="_blank" rel="noopener noreferrer">
                            <img
                            src={Telegram}
                            alt="Telegram logo in light blue"
                            style={{ width: '33px'}}
                            />
                         </StyledLinkButton>
                    </SocialButton>
                    <SocialButton>
                        <StyledLinkButton href="https://x.com/foxi_tips" target="_blank" rel="noopener noreferrer">
                            <img
                            src={X}
                            alt="X (formerly Twitter) logo in white"
                            style={{ width: '33px'}}
                            />
                          </StyledLinkButton>
                    </SocialButton>
                    <SocialButton>
                        <StyledLinkButton href="https://youtube.com/@foxi_tips" target="_blank" rel="noopener noreferrer">
                            <img
                            src={Youtube}
                            alt="YouTube logo in red"
                            style={{ width: '33px'}}
                            />
                        </StyledLinkButton>
                    </SocialButton>
                </SocialContainer>
             </Box>
        </Box>

      </ContentContainer>

        {/* Gradient Box Shadow */}
        <GradientBoxShadow customStyle={{ position: 'absolute', top: '60vh', left: '40vw', zIndex: 0 }} />
    </PageContainer>
  );
}