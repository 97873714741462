import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { List, Grid, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import Loading from '../Loading/Loading';
import {
  TASK_ICONS,
  REWARD_ICONS,
} from '../Task';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const StyledTaskButton = styled(Button)(({ theme, active = false }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '6.5vh',
  width: '26%',
  padding: active ? '12px 18px' : '12px 16px',
  backgroundColor: active ? '#3d04c7' : '#5529ec',
  backgroundImage: active 
    ? 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)' 
    : '#5529ec',
  boxShadow: `
    inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6),
    inset 0 -4px 4px 1px #c5b3ff,
    inset 0 0 0px 8px rgba(255, 255, 255, 0.2),
    0 1px 4px 1px rgba(56, 41, 236, 0.2),
    0 1px 3px 1px #5529ec
  `,
  textShadow: active ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'all 0.2s ease-in-out',
  cursor: 'pointer',
  fontSize: active ? '14px' : '13px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  filter: active ? 'hue-rotate(300deg)' : 'none',
  '&:hover': {
    transform: 'scale(1.09)',
    filter: 'hue-rotate(300deg)',
  },
  '&:active': {
    transform: 'scale(0.96)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.3) !important',
  }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  background: '#00000086',
  backdropFilter: 'blur(4px)',
  borderRadius: '40px',
  marginBottom: '8px',
  marginLeft: '8px',
  padding: '0',
  width: '94vw',
  height: '10vh',
  maxHeight: '15vh',
  paddingRight: '8px',
  paddingLeft: '12px',
  animation: `${fadeIn} 0.5s ease-in-out`,
  transition: 'background-color 0.3s ease, opacity 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 25, 50, 0.8)',
  }
}));

const TaskList = memo(({ tasks, taskType = "basic", completedTasks = [], showSnackbar, onTaskSelect }) => {
  // Render rewards section
  const renderRewards = useCallback((task) => {
    const hasDiamondRewards = task.diamonds > 0;
    
    return (
      <>
        {hasDiamondRewards && (
          <>
            <img 
              src={REWARD_ICONS.DIAMOND} 
              alt="Diamond" 
              style={{ width: '18px', marginRight: '2px', verticalAlign: 'middle' }} 
            />
            {`${task.diamonds}`}
            &nbsp;&nbsp;
          </>
        )}
        <img 
          src={REWARD_ICONS.COIN} 
          alt="Points" 
          style={{ width: hasDiamondRewards ? '16px' : '17px', marginRight: '2px', verticalAlign: 'middle' }} 
        />
        {`${task.points}`}
      </>
    );
  }, []);

  // Handle task selection
  const handleTaskClick = useCallback((task) => {
    if (!completedTasks) {
      showSnackbar('Please log in to perform tasks.', 'error');
      return;
    }

    if (completedTasks.includes(task.taskId)) {
      showSnackbar('You have already completed this task!', 'info');
      return;
    }

    onTaskSelect(task.taskId);
  }, [completedTasks, showSnackbar, onTaskSelect]);

  // Render task item
  const renderTaskItem = useCallback((task, index) => {
    const completed = completedTasks.includes(task.taskId);
    const taskIcon = TASK_ICONS[task.category];

    return (
      <StyledListItem 
        key={task.taskId || index}
        onClick={() => handleTaskClick(task)}
        style={{ 
          animationDelay: `${index * 0.075}s`,
          opacity: completed ? 0.7 : 1,
          cursor: completed ? 'default' : 'pointer'
        }}
      >
        <ListItemIcon sx={{ mr: 1 }}>
          {taskIcon && <img 
            src={taskIcon} 
            alt={task.category} 
            width="60px" 
            height="60px" 
          />}
        </ListItemIcon>

        <ListItemText 
          primary={task.title}
          secondary={renderRewards(task)}
          primaryTypographyProps={{ 
            fontSize: '14.5px', 
            fontFamily: 'Sora, sans-serif', 
            fontWeight: 'bold', 
            color: 'white', 
            lineHeight: '18px' 
          }}
          secondaryTypographyProps={{ 
            fontFamily: 'Poppins-Medium, sans-serif', 
            color: 'white' 
          }}
          sx={{ width: '85%' }}
        />

        <StyledTaskButton 
          disabled={completed}
          active={!completed}
          onClick={(e) => {
            e.stopPropagation();
            handleTaskClick(task);
          }}
        >
          {completed ? 'Done' : 'Start'}
        </StyledTaskButton>
      </StyledListItem>
    );
  }, [completedTasks, handleTaskClick, renderRewards]);

  // Filter and sort tasks
  const getFilteredTasks = useCallback(() => {
    if (!tasks?.length) return [];
    
    return tasks
      .filter(task => task.type?.toLowerCase() === taskType?.toLowerCase())
      .sort((a, b) => {
        const aCompleted = completedTasks.includes(a.taskId) ? 1 : 0;
        const bCompleted = completedTasks.includes(b.taskId) ? 1 : 0;
        return aCompleted - bCompleted;
      });
  }, [tasks, taskType, completedTasks]);

  if (!tasks?.length) {
    return <Loading loading={true} customStyle={{ left: '40vw', top: '45vh'}} />;
  }

  const filteredTasks = getFilteredTasks();

  return (
    <Grid 
      container 
      spacing={2} 
      pt={2} 
      sx={{ 
        height: '60vh',
        overflowY: 'scroll',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'space-between',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}
    >
      <List sx={{ alignItems: 'center', alignContent: 'center' }}>
        {filteredTasks.map(renderTaskItem)}
      </List>
    </Grid>
  );
});

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    taskId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    diamonds: PropTypes.number.isRequired,
    link: PropTypes.string.isRequired,
    completionsCount: PropTypes.number
  })).isRequired,
  taskType: PropTypes.string,
  completedTasks: PropTypes.arrayOf(PropTypes.string),
  showSnackbar: PropTypes.func.isRequired,
  onTaskSelect: PropTypes.func.isRequired
};

TaskList.displayName = 'TaskList';

export default TaskList;