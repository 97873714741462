import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { CopyAllSharp } from '@mui/icons-material';
import { styled } from '@mui/system';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  position: 'fixed',
  bottom: '19.5vh',
  width: '93vw',
  height: '10vh',
  zIndex: 10,
  backgroundColor: '#140924b8',
  border: '1px solid #8447ff',
  borderRadius: '30px',
  backdropFilter: 'blur(5px)',
  marginBottom: '8px'
});

const ActionButton = styled('button')(({ isCopyButton }) => ({
  borderRadius: '10rem',
  color: 'white',
  width: isCopyButton ? '18%' : '75%',
  height: '8vh',
  padding: '12px 16px',
  backgroundColor: '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  textShadow: '0 1px 1px #3829ec',
  textTransform: 'inherit',
  fontSize: '1rem',
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  cursor: 'pointer',
  margin: '3px',
  filter: 'hue-rotate(300deg)',
  border: 'none',
  transition: 'transform 0.2s ease',
  willChange: 'transform',

  '&:hover': {
    transform: 'scale(1.04)'
  },

  '&:active': {
    transform: 'scale(0.96)',
    backgroundColor: '#2e23d6'
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: isCopyButton ? '60%' : '80%',
    height: isCopyButton ? '30%' : '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75
  }
}));

const ShareLink = memo(({ shareMessageLink, copyToClipboard }) => {
  const handleShare = () => {
    window.open(shareMessageLink, '_blank');
  };

  return (
    <Container>
      <ActionButton onClick={handleShare}>
        Share Invite Link
      </ActionButton>
      <ActionButton onClick={copyToClipboard} isCopyButton>
        <CopyAllSharp sx={{ color: 'white' }} />
      </ActionButton>
    </Container>
  );
});

ShareLink.propTypes = {
  shareMessageLink: PropTypes.string.isRequired,
  copyToClipboard: PropTypes.func.isRequired
};

ShareLink.displayName = 'ShareLink';

export default ShareLink;