import React, { useEffect, useState, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import SpinWheelGame from './Rewards/SpinWheel/SpinWheelGame';
import BottomTab from './Tabs/Tabs';
import BalanceBoxes from './Rewards/BalanceBoxes/BalanceBoxes';

const jumpKeyframes = keyframes`
  0% { transform: translate(0, 0) scaleX(1) scaleY(1); }
  50% { transform: translate(0px, -30px) scaleX(0.8) scaleY(1.2); }
  80% { transform: translate(0, 0) scaleX(1.2) scaleY(0.8); }
  100% { transform: translate(0, 0) scaleX(1) scaleY(1); }
`;

const rotateKeyframes = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const AnimatedStar = styled(Box)(({ isJumping, isRotating }) => ({
  position: 'relative',
  width: '40px',
  height: '40px',
  animation: isJumping
    ? `${jumpKeyframes} 0.6s ease-out forwards`
    : isRotating
    ? `${rotateKeyframes} 0.5s ease-out forwards`
    : 'none',
  filter: 'drop-shadow(0 0 13px gold) drop-shadow(0 0 5px gold)',
}));

function Rewards({ userProfileInfo, setUserProfileInfo, showSnackbar, telApp }) {
  const [isJumping, setIsJumping] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleBalanceUpdate = useCallback((newBalance) => {
    setUserProfileInfo(prevState => {
      const updatedState = {
        ...prevState,
        diamonds: newBalance.diamonds,
        points: newBalance.points,
      };
      return updatedState;
    });
  }, [setUserProfileInfo]);

  useEffect(() => {
    // Set Header Color
    telApp.setHeaderColor('#010510');
    const animationSequence = async () => {
      setIsJumping(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsJumping(false);

      setIsRotating(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsRotating(false);
    };

    animationSequence();

    const intervalId = setInterval(animationSequence, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ height: '100vh', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection={'column'}
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 1, mt: 5 }}
        >
          <Typography
            variant="h4"
            fontFamily="Poppins-Bold, sans-serif"
            fontWeight="bold"
            zIndex={1}
            sx={{ color: 'white' }}
          >
            R E W 
            <AnimatedStar
              component="img"
              src="https://img.icons8.com/pulsar-gradient/50/star.png"
              alt="Star"
              isJumping={isJumping}
              isRotating={isRotating}
            /> 
            R D S
          </Typography>
          <Typography
            variant="body1"
            fontFamily="sora, sans-serif"
            fontWeight="bold"
            zIndex={1}
            sx={{ color: '#f48fb1' }}
          >
            Try your luck!
          </Typography>
        </Grid>

        <BalanceBoxes isSpinning={isSpinning} userProfileInfo={userProfileInfo} />

        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
          <SpinWheelGame 
            userProfileInfo={userProfileInfo}
            showSnackbar={showSnackbar}
            onBalanceUpdate={handleBalanceUpdate}
            isSpinning={isSpinning}
            setIsSpinning={setIsSpinning}
          />
        </Box>
      </Grid>

      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 10 }}>
        <BottomTab activeTab={2} />
      </Box>
    </Box>
  );
}

export default Rewards;