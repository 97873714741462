import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { taskApi } from '../../services/api';
import AlertBadge from '../AlertBadge/AlertBadge';
import {
  TASK_ICONS,
  REWARD_ICONS,
  TASK_CATEGORIES,
  TASK_REQUIREMENTS,
  getTaskRequirements
} from '../Task';

// Verification States
const VERIFICATION_STATES = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed'
};

// Styled Components
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: '55vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  borderTopLeftRadius: '35px',
  borderTopRightRadius: '35px',
  borderTop: '5px solid orange',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));

const StyledTaskButton = styled(Button, {
  shouldForwardProp: prop => !['isActive', 'isWaiting'].includes(prop)
})(({ theme, isActive, isWaiting }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '7.5vh',
  width: '80%',
  padding: '12px 16px',
  backgroundColor: isActive ? 'rgb(4 75 255)' : 
                 isWaiting ? 'rgb(4 75 255)' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: 'inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec',
  textShadow: isActive ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'all 0.2s ease-in-out',
  cursor: isWaiting ? 'not-allowed' : 'pointer',
  fontSize: '17px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  opacity: isWaiting ? 0.7 : 1,
  filter: 'hue-rotate(300deg)',
  position: 'relative',
  bottom: '55px',
  '&:hover': {
    transform: isWaiting ? 'none' : 'scale(1.02)',
    backgroundColor: 'rgb(232 4 255)',
  },
  '&:active': {
    transform: isWaiting ? 'none' : 'scale(0.98)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:disabled': {
    backgroundColor: '#666',
    cursor: 'not-allowed',
  }
}));

const ConfirmTaskPopup = memo(({ 
  task, 
  userId, 
  open, 
  onClose, 
  setOpenConfetti,
  showSnackbar
}) => {
  const [verificationState, setVerificationState] = useState(VERIFICATION_STATES.NOT_STARTED);
  const [hasFailedAttempt, setHasFailedAttempt] = useState(false);
  const taskStartTimeRef = useRef(0);

  const resetVerification = useCallback(() => {
    setVerificationState(VERIFICATION_STATES.NOT_STARTED);
    taskStartTimeRef.current = 0;
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      if (verificationState !== VERIFICATION_STATES.IN_PROGRESS || !taskStartTimeRef.current) return;

      const timeSpent = (Date.now() - taskStartTimeRef.current) / 1000;
      const requirements = getTaskRequirements(task?.category, task?.title);

      if (task?.category === TASK_CATEGORIES.TELEGRAM) {
        setVerificationState(VERIFICATION_STATES.COMPLETED);
        return;
      }

      if (requirements) {
        if (timeSpent < requirements.minTime) {
          setHasFailedAttempt(true);
          showSnackbar(requirements.errorMessage, 'error', true);
          resetVerification();
        } else {
          setVerificationState(VERIFICATION_STATES.COMPLETED);
        }
      }
    };

    if (open) {
      window.addEventListener('focus', handleFocus);
    }

    return () => window.removeEventListener('focus', handleFocus);
  }, [open, verificationState, task, showSnackbar, resetVerification]);

  useEffect(() => {
    if (!open) {
      resetVerification();
      setHasFailedAttempt(false);
    }
  }, [open, resetVerification]);

  const handleStartTask = useCallback(() => {
    if (!task?.link) {
      showSnackbar('Invalid task link', 'error');
      return;
    }

    taskStartTimeRef.current = Date.now();
    setVerificationState(VERIFICATION_STATES.IN_PROGRESS);
    window.open(task.link, '_blank');
  }, [task, showSnackbar]);

  const handleTaskVerification = useCallback(async () => {
    // First validate task and userId
    if (!task?.taskId || !userId) {
      showSnackbar('Loading task data, please try again in a moment.', 'info');
      return;
    }
  
    // Then check verification state for non-Telegram tasks
    if (task?.category !== TASK_CATEGORIES.TELEGRAM && 
        verificationState !== VERIFICATION_STATES.COMPLETED) {
      const requirements = getTaskRequirements(task?.category, task?.title);
      if (requirements) {
        showSnackbar(requirements.errorMessage, 'info');
        return;
      }
    }
    
    try {
      // Add a small delay for the first verification attempt
      await new Promise(resolve => setTimeout(resolve, 500));
  
      const response = await taskApi.checkTask(userId, task.taskId);
      
      if (!response?.data?.success) {
        throw new Error(response?.data?.error || 'Verification failed');
      }
  
      setOpenConfetti(true);
      onClose();
      showSnackbar('Task completed successfully!', 'success');
    } catch (error) {
      console.error('Error verifying task:', error);
      
      // Handle specific error messages
      if (error?.response?.data?.error) {
        showSnackbar(error.response.data.error, 'error', true);
      } else if (error.message === 'Error fetching task or user data') {
        showSnackbar('Please wait a moment and try again', 'error', true);
      } else {
        showSnackbar('Failed to verify task. Please try again.', 'error');
      }
  
      // If it's a data fetching error, don't close the popup
      if (error.message !== 'Error fetching task or user data') {
        onClose();
      }
    }
  }, [verificationState, task, userId, onClose, setOpenConfetti, showSnackbar]);

  const renderRewards = useCallback(() => {
    if (!task) return null;
    
    const hasDiamondRewards = task.diamonds > 0;
    
    return (
      <Box bgcolor='rgba(255, 255, 255, 0.2)' sx={{ borderRadius: '40px', padding: '6px 10px' }}>
        {hasDiamondRewards && (
          <>
            <img 
              src={REWARD_ICONS.DIAMOND} 
              alt="Diamond" 
              style={{ width: '18px', marginRight: '2px', verticalAlign: 'middle' }} 
            />
            {`${task.diamonds}`}
            &nbsp;&nbsp;
          </>
        )}
        <img 
          src={REWARD_ICONS.COIN} 
          alt="Points" 
          style={{ width: hasDiamondRewards ? '16px' : '17px', marginRight: '2px', verticalAlign: 'middle' }} 
        />
        {`${task.points}`}
      </Box>
    );
  }, [task]);

  const isTelegramTask = task?.category === TASK_CATEGORIES.TELEGRAM;
  const showRestartButton = hasFailedAttempt && verificationState === VERIFICATION_STATES.NOT_STARTED;
  const showVerifyButton = isTelegramTask || verificationState === VERIFICATION_STATES.COMPLETED;

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <Box display="flex" justifyContent="space-between" mt={2} mr={2}>
        <Typography 
          variant="h5" 
          fontFamily="Poppins-Medium, sans-serif"
          fontSize="20px" 
          ml={2}
        >
          {task?.category} Task
        </Typography>
        <IconButton onClick={onClose} aria-label="close popup">
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box display="flex" gap="5px" flexDirection="column" justifyContent="center" alignItems="center" position="relative" bottom="20px">
          <img src={TASK_ICONS[task?.category]} alt={task?.type} width="110px" height="110px" />
        <Box display="flex" flexDirection="column" gap="5px" position="relative" bottom="8px">
          <Typography variant="h6" fontFamily="Poppins-Medium, sans-serif" fontSize="20px" ml={2}>
            {task?.title.length > 35 ? `${task?.title.slice(0, 35)}...` : task?.title}
          </Typography>
           <Box display="flex" gap="5px" justifyContent="center" alignItems="center" mt={0.25}>
            {renderRewards()}
           </Box>
        </Box>
      </Box>


      <Box 
        display="flex" 
        gap="8px"
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        width="100%" 
        height="65%" 
        position="relative" 
        bottom="20px"
      > 
          <StyledTaskButton 
            onClick={handleStartTask}
            disabled={verificationState === VERIFICATION_STATES.IN_PROGRESS}
            aria-label="start task"
          >
            {verificationState === VERIFICATION_STATES.NOT_STARTED 
              ? (hasFailedAttempt ? 'Restart Task' : 'Start Task')
              : 'Restart Task'}
              
               {showRestartButton && (
                  <AlertBadge pulseSpeed={1} top={-4} right={0} size={17} />
               )}
          </StyledTaskButton>

          <StyledTaskButton 
            onClick={handleTaskVerification}
            disabled={!showVerifyButton}
            isActive={showVerifyButton}
            aria-label="verify task completion"
          >
            {isTelegramTask ? 'Verify Task' : 
             verificationState === VERIFICATION_STATES.COMPLETED ? 'Verify Task' : 
             'Complete Task First'}
            {verificationState === VERIFICATION_STATES.COMPLETED && !isTelegramTask && (
              <AlertBadge pulseSpeed={1} top={-4} right={0} size={17} />
            )}
          </StyledTaskButton>
      </Box>
    </PopupContainer>
  );
});

ConfirmTaskPopup.propTypes = {
  task: PropTypes.shape({
    taskId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    diamonds: PropTypes.number
  }),
  userId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setOpenConfetti: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

ConfirmTaskPopup.displayName = 'ConfirmTaskPopup';

export default ConfirmTaskPopup;