import React from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const StyledGradientBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '60vh',
    right: '10vw',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    width: '0%',
    height: '5%',
    overflow: 'hidden',
    pointerEvents: 'none',
    animation:`${rotate} 17s linear infinite`,
    transition: 'all 2s ease-in-out',
    boxShadow: 'rgb(255 135 27 / 93%) -20vh -13vh 20vh 13vh, #4b32ffcf -15vh 20vh 20vh 15vh, #ff0034c4 15vh -16vh 20vh 14vh, rgb(0 221 255) 12vh -8vh 17vh 19vh, rgba(255, 0, 159, 0.9) -16vh 11vh 23vh 4.44vh',
    opacity: 0.9,
    zIndex: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
  }));

// rotate animation
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const GradientBoxShadow = ({ customStyle = null }) => {
  return (
    <StyledGradientBox sx={{ ...customStyle }} />
 );
}

export default GradientBoxShadow;
