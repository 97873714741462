import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { formatNumber } from '../../utils/Formatters';
import FoxiCup from '../../assets/images/home-elements/new-leauge/foxi-cup.png';

// Styled Components
const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: '55vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  borderTop: '5px solid orange',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '30px',
  borderTopRightRadius: '30px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  margin: '0 auto',
  zIndex: 999,
  '*': { color: 'white' },
  willChange: 'transform',
}));

const PopupImage = styled('img')({
  width: '135px',
  height: '135px',
  position: 'relative',
  bottom: '5px',
  left: '17vw',
  transform: 'translateX(50%)',
  pointerEvents: 'none',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  top: '15px',
});

const Title = styled(Typography)({
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bolder',
  fontSize: '20px',
  textAlign: 'center',
  padding: '0 16px',
  margin: '4px 0',
});

const Subtitle = styled(Typography)({
  fontFamily: 'Sora, sans-serif',
  fontWeight: 'bold',
  fontSize: '16px',
  textAlign: 'center',
  padding: '6px 0',
  marginBottom: '4px',
  background: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '10px',
  width: '90vw',
  margin: '0 auto',
  placeItems: 'center',
});

const NewLeaguePopup = memo(function NewLeaguePopup({ 
  open, 
  onClose, 
  league, 
  StyledButton, 
  setOpenConfetti 
}) {
  // Handlers
  const handleClaimRewards = useCallback(async () => {
    onClose();
    setOpenConfetti(true);
  }, [onClose, setOpenConfetti]);

  // Dynamic styles
  const containerStyle = {
    transform: open ? 'translateY(0)' : 'translateY(107%)',
  };

  const buttonStyle = {
    width: '90vw',
    height: '50px',
    fontSize: '18px',
    fontFamily: 'Sora, sans-serif',
    fontWeight: 'bold',
    margin: '0 auto',
    mt: '10px'
  };

  return (
    <PopupContainer 
      sx={containerStyle} 
      onClose={onClose}
      role="dialog"
      aria-modal="true"
      aria-labelledby="new-league-title"
    >
      <ContentContainer>
        <PopupImage
          src={FoxiCup}
          alt="League Trophy"
          loading="lazy"
        />
        
        <Title id="new-league-title">
          New League Achieved
        </Title>
        
        <Subtitle>
          Congratulations! You have successfully reached the {league?.current} League <br/>
        </Subtitle>

        <Typography
           variant="body1"
           fontFamily="Sora, sans-serif"
           textAlign="center"
           mt="10px"
        >
          Your new daily budget is {formatNumber(league?.allowance)}
        </Typography>     

        <StyledButton 
          onClick={handleClaimRewards} 
          sx={buttonStyle}
        >
          Okay
        </StyledButton>
      </ContentContainer>
    </PopupContainer>
  );
});

NewLeaguePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  league: PropTypes.shape({
    current: PropTypes.string,
    index: PropTypes.number,
    allowance: PropTypes.number,
    nextRequired: PropTypes.number,
    achievedNew: PropTypes.bool,
    referrerReward: PropTypes.number
  }),
  StyledButton: PropTypes.elementType.isRequired,
  setOpenConfetti: PropTypes.func.isRequired,
};

export default NewLeaguePopup;