import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { Box, Typography, Button, LinearProgress, Avatar, Paper } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { styled, keyframes } from '@mui/system';
import { userApi } from '../services/api';
import { calculateRemainingTime_HMS } from '../utils/Time';
import { formatNumber } from '../utils/Formatters';

// Base imports
import BottomTabs from './Tabs/Tabs';
import Confetti from './Confetti/Confetti';

// Import images
import FoxiCharacter from '../assets/images/home-elements/foxi-character.png';
import DiamondPng from '../assets/images/home-elements/diamond.png';
import CheckInPng from '../assets/images/home-elements/check-in.png';
import ReceiveTipIcon from '../assets/images/home-elements/receive-tip.png';
import SendTipIcon from '../assets/images/home-elements/send-tip.png';
import BG3 from '../assets/images/home-elements/bg-3.jpg';

// Lazy load components
import SendTipPopup from './Home/SendTipPopup';
import HistoryPopup from './Home/HistoryPopup';
import LeaguesPopup from './Home/LeaguesPopup';
import NewLeaguePopup from './Home/NewLeaguePopup';
import DailyCheckIn from './Home/CheckInPopup';
import NotTapTapPopup from './Home/NotTapTapPopup';
import ParticleSystem from './Home/ParticleSystem';
import AlertBadge from './AlertBadge/AlertBadge';

// Animations
const pulse = keyframes`
  0%, 100% { transform: scale3d(1, 1, 1); }
  50% { transform: scale3d(1.05, 1.05, 1); }
`;

const float = keyframes`
  0%, 100% { transform: translate3d(0, 0, 0); }
  50% { transform: translate3d(0, -10px, 0); }
`;

const rotate360 = keyframes`
  from { transform: rotate3d(0, 0, 1, 0deg); }
  to { transform: rotate3d(0, 0, 1, 360deg); }
`;

const shadowAnimation = keyframes`
  0% { transform: scaleX(1); }
  50% { transform: scaleX(1.2); }
  100% { transform: scaleX(1); }
`;

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  background: `url(${BG3})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  padding: 0,
  borderRadius: 0,
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "93%",
  height: "5vh",
  marginTop: '2vh',
  position: "relative",
  padding: theme.spacing(1, 2),
  paddingBottom: 0,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const GlowingCircle = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '40vh',
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%)',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  marginBottom: theme.spacing(1),
  animation: `${float} 3s ease-in-out infinite`,
  transform: 'translate3d(0, 0, 0)',
  backfaceVisibility: 'hidden',
  perspective: 1000,
  willChange: 'transform',
  '& > *': {
    backfaceVisibility: 'hidden',
    perspective: 1000,
    willChange: 'transform',
  }
}));

const AnimatedCircle = styled(Box)(({ rotation = '250deg' }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: 'translate3d(0, 0, 0) rotate(' + rotation + ')',
  backfaceVisibility: 'hidden',
  perspective: 1000,
  willChange: 'transform',
  animation: `${rotate360} 10s linear infinite`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10rem',
  textTransform: 'none',
  height: '7vh',
  padding: '12px 16px',
  backgroundColor: '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  color: 'white',
  textShadow: '0 1px 1px #3829ec',
  transition: 'border-color 0.2s, transform 0.2s, background-color 0.2s',
  cursor: 'pointer',
  margin: '3px',
  filter: 'hue-rotate(335deg)',
  '&:hover': {
    transform: 'scale(1.04)',
    filter: 'hue-rotate(85deg)',
  },
  '&:active': {
    borderColor: '#2e23d6',
    transform: 'scale(0.96)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:focus': {
    outline: 'none',
  }
}));

const TipBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(13, 30, 53, 0.7)',
  borderRadius: theme.spacing(4),
  padding: '5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '46%',
  height: '7vh',
  transition: 'all 0.3s ease',
  zIndex: 2,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(224, 224, 224, 0.3)',
  '& .MuiLinearProgress-bar': {
    backgroundImage: 'linear-gradient(to right, #0984ff, #e063df, #5cff62)',
  },
}));

const Shadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '2vh',
  width: '25vh',
  height: '4vh',
  background: 'rgba(0, 0, 0, 0.75)',
  borderRadius: '50%',
  filter: 'blur(9px)',
  animation: `${shadowAnimation} 3s ease-in-out infinite`,
  zIndex: 1,
}));

// Memoized Components
const Header = React.memo(({ userProfileInfo, onCheckInClick, canCheckIn }) => {
  return (
    <HeaderContainer>
      <Button
        variant="contained"
        size="small"
        sx={{ 
          textTransform: 'none', 
          background: 'linear-gradient(45deg, #4a50ea, #8c6edf)',
          borderRadius: '8px 22px 8px 8px',
          height: '5vh',
          width: '23vw',
          minWidth: 'auto',
          transition: 'all 0.3s ease',
          paddingRight: '15px',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
        onClick={onCheckInClick}
      >
        {canCheckIn && <AlertBadge pulseSpeed={1} top={-4} right={80} size={17}>!</AlertBadge>}
        <img src={CheckInPng} alt="Check-in" width="30px" height="53px" />
        <Typography fontSize="13px" fontFamily="sora, sans-serif" fontWeight={500} sx={{ lineHeight: '13px' }}>
          daily<br/>check
        </Typography>
      </Button>
      
      <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} mb="4vh" mr={2}>
        <img 
          width="30px" 
          height="30px" 
          src="https://img.icons8.com/external-icongeek26-outline-gradient-icongeek26/64/external-fox-origami-icongeek26-outline-gradient-icongeek26.png" 
          alt="Polygon" 
          style={{ marginRight: '2px' }} 
        />
        <Typography fontSize="17px" fontFamily="sora, sans-serif" fontWeight={400}>
          {userProfileInfo?.profile?.firstName 
            ? userProfileInfo.profile.firstName.length > 10 
              ? `${userProfileInfo.profile.firstName.slice(0, 10)}...` 
              : userProfileInfo.profile.firstName 
            : 'Loading...'}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" sx={{ 
        textTransform: 'none', 
        background: 'linear-gradient(45deg, #4a50ea, #8c6edf)',
        borderRadius: '22px 8px 8px 8px',
        width: '18vw',
        height: '5vh',
        px: 1,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}>
        <Typography fontSize="16px" fontFamily="sora, sans-serif" fontWeight={600} sx={{ ml: '15px', mr: 'auto' }}>
          {formatNumber(userProfileInfo?.treasury?.diamonds || 0)}
        </Typography>
        <img 
          src={DiamondPng} 
          alt="diamond" 
          width="85px" 
          height="105px" 
          style={{ 
            position: 'relative', 
            right: '0.9rem', 
            bottom: '0.1rem', 
            transform: 'rotate(45deg)' 
          }} 
        />
      </Box>
    </HeaderContainer>
  );
});

// Memoized Stats Component
const StatsSection = React.memo(({ tipMetrics, onTipBoardClick }) => {
  return (
    <Box display="flex" justifyContent="space-between" mb={1.5}>
      <TipBox onClick={() => onTipBoardClick('received')}>
        <Box component="img" src={ReceiveTipIcon} alt="receive-tip-icon" width="37px" height="65px" mr={1} ml={1} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography variant="caption" fontFamily="sora, sans-serif" fontWeight={600}>
            Tip's Received
          </Typography>
          <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight={600} mx="auto">
            {formatNumber(tipMetrics?.received || 0)}
          </Typography>
        </Box>
      </TipBox>
      
      <TipBox onClick={() => onTipBoardClick('sent')}>
        <Box component="img" src={SendTipIcon} alt="send-tip-icon" width="37px" height="37px" mr={0.5} ml={1} />
        <Box display="flex" flexDirection="column" justifyContent="center" ml={2}>
          <Typography variant="caption" fontFamily="sora, sans-serif" fontWeight={600}>
            Tip's Sent
          </Typography>
          <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight={600} mx="auto">
            {formatNumber(tipMetrics?.sent || 0)}
          </Typography>
        </Box>
      </TipBox>
    </Box>
  );
});

// Memoized Progress Component
const ProgressSection = React.memo(({ userProfileInfo, progressPercentage, handleOpenLeaguePopup }) => {
  return (
    <>
      <Box onClick={handleOpenLeaguePopup} display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={0.5}>
        <Typography variant="body2" fontFamily="sora, sans-serif">
          Points: {formatNumber(userProfileInfo?.treasury?.points || 0)}
        </Typography>
        <Typography variant="body2" align="center" fontFamily="sora, sans-serif">
          {`Level ${userProfileInfo?.league?.index || 0}/10`}
        </Typography>
      </Box>
      <StyledLinearProgress variant="determinate" value={progressPercentage} sx={{ mb: 1.5 }} />
    </>
  );
});

// Memoized Budget Display Component
const BudgetDisplay = React.memo(({ treasury, initialBudget, isSmallScreen }) => {
  return (
    <>
      <Typography variant="h6" align="center" fontFamily="sora, poppins" fontWeight={800} sx={{ zIndex: 9, mb: 0.5 }}>
        Daily Budget
      </Typography>
      <Typography 
        fontSize={isSmallScreen ? "24px" : "32px"} 
        align="center" 
        fontFamily="sora, poppins" 
        fontWeight={800} 
        sx={{ 
          zIndex: 9, 
          lineHeight: '1',
          animation: `${pulse} 2s infinite ease-in-out`,
        }}
      >
        {formatNumber(treasury?.allowance || 0)} 
        <Typography component="span" variant="h6" fontWeight={600}>
          / {formatNumber(initialBudget || 0)}
        </Typography>
      </Typography>
    </>
  );
});

// Memoized Farm Button Component
const FarmButton = React.memo(({ 
  farming,
  isActivatingFarmingButton, 
  handleFarming,
  remainingNextFarming,
  farmingProgressPercent 
}) => {
  const requiresRewardClaim = farming?.requiresRewardClaim || false;
  const isActive = farming?.isActive || false;

  if (requiresRewardClaim) {
    return (
      <StyledButton 
        variant="contained" 
        onClick={handleFarming}
        sx={{ 
          flex: '1.65', 
          justifyContent: 'space-evenly', 
          borderRadius: '10rem',
          textAlign: 'center',
          alignItems: 'center',
          px: '5px',
          fontSize: '2.4vh',
          fontFamily: 'sora, sans-serif',
          fontWeight: 'bold',
          '::after': { content: '""', width: '80%', height: '35%', left: '53.5%', } 
        }}
      >
        <AlertBadge pulseSpeed={1} top={-4} right={0} size={17}>!</AlertBadge>
        Claim Rewards 
      </StyledButton>
    );
  }

  return (
    <StyledButton 
      variant="contained" 
      onClick={handleFarming}
      sx={{ 
        flex: '2', 
        justifyContent: 'space-evenly', 
        paddingLeft: '0px',
        borderRadius: '10rem' 
      }}
    >
      <Typography 
        fontSize={isActive ? "0.95rem" : "1.1rem"} 
        fontFamily="sora, sans-serif" 
        fontWeight="bold" 
        display="flex" 
        alignItems="flex-end" 
        pr={2} 
        pl={1}
      >
        <img 
          width="25" 
          height="25" 
          src="https://img.icons8.com/3d-fluency/94/tractor.png" 
          alt="tractor" 
          style={{ marginRight: '0.35rem' }}
        />
        {isActivatingFarmingButton ? "Activating" : isActive ? "Farming.." : "Farm"} 
      </Typography>
      {isActive && (
        <Typography 
          fontSize="0.85rem" 
          display="block" 
          fontFamily="sora, sans-serif" 
          fontWeight="bold"
          sx={{ 
            color: 'gold',
            whiteSpace: 'nowrap', 
            width: '100px', 
            height: '30px', 
            lineHeight: '16px',
            position: 'relative',
            left: '10px',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '-3px',
              left: '-7px',
              width: '110%',
              height: '125%',
              opacity: '0.85',
              borderLeft: '5px solid #4815ff',
              borderRadius: '25px',
              zIndex: '1',
            }
          }}
        > 
          {remainingNextFarming || "00:00:00"} <br /> 
          {`${formatNumber(farmingProgressPercent * 0.8)} Point`}
        </Typography>
      )}
    </StyledButton>
  );
});

// Main component logic starts here...
function Home(props) {
  const { 
    userData, 
    setUserProfileInfo, 
    userProfileInfo, 
    showSnackbar, 
    telApp, 
    ENDPOINT 
  } = props;

  // Theme setup
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // State management
  const [openLeaguesPopup, setOpenLeaguesPopup] = useState(false);
  const [openSendTipPopup, setOpenSendTipPopup] = useState(false);
  const [openCheckInPopup, setOpenCheckInPopup] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [openNewLeaguePopup, setOpenNewLeaguePopup] = useState(false);
  const [openNotTapTapPopup, setOpenNotTapTapPopup] = useState(false);
  const [historyActivityType, setHistoryActivityType] = useState('received');

  const [isActivatingFarmingButton, setIsActivatingFarmingButton] = useState(false);
  const [levelProgressPercentage, setLevelProgressPercentage] = useState(0);
  const [canCheckIn, setCanCheckIn] = useState(false);
  const [farmingProgressPercent, setFarmingProgressPercent] = useState(0);
  const [remainingNextFarming, setRemainingNextFarming] = useState(null);
  const [openConfetti, setOpenConfetti] = useState(false);

  const [farmStartTime, setFarmStartTime] = useState(null);
  const [farmingIntervalMs] = useState(6 * 60 * 60 * 1000); // 6 hours

  // Update user profile info effect
  useEffect(() => {
    if (userProfileInfo) {
      setOpenNewLeaguePopup(userProfileInfo.league?.achievedNew || false);
      
      // Calculate level progress
      if (userProfileInfo.treasury?.points && userProfileInfo.league?.nextRequired) {
        const totalPoints = userProfileInfo.league.nextRequired + userProfileInfo.treasury.points;
        setLevelProgressPercentage(Math.round(
          (userProfileInfo.treasury.points / totalPoints) * 100
        ));
      }
    }
  }, [userProfileInfo]);

  // Fetch check-in status
  const fetchDailyCheckInStatus = useCallback(async () => {
    try {
      const response = await userApi.getDailyCheckInStatus(userData.id);
      if (response.data.success) {
        setCanCheckIn(response.data.data.canCheckIn);
      }
    } catch (error) {
      console.error('Error fetching check-in status:', error);
    }
  }, [userData?.id]);

  // Initial setup
  useEffect(() => {
    fetchDailyCheckInStatus();
    telApp.setHeaderColor('#22252a');
  }, [fetchDailyCheckInStatus, openCheckInPopup, telApp]);

  // Farming progress effect
  useEffect(() => {
    let intervalId;

    if (farmStartTime) {
      const updateFarmingProgress = () => {
        const now = Date.now();
        const nextFarmingTimeMs = farmStartTime + farmingIntervalMs;
        const remainingTime = calculateRemainingTime_HMS(now, nextFarmingTimeMs);
        const passedTimePercentage = 100 - Math.round(100 * (nextFarmingTimeMs - now) / farmingIntervalMs);
        
        setFarmingProgressPercent(passedTimePercentage);
        setRemainingNextFarming(
          `${remainingTime.hours}:${remainingTime.minutes}:${remainingTime.seconds === 0 ? '00' : remainingTime.seconds}`
        );

        if (now >= nextFarmingTimeMs) {
          clearInterval(intervalId);
          setFarmStartTime(null);
        }
      };

      updateFarmingProgress();
      intervalId = setInterval(updateFarmingProgress, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [farmStartTime, farmingIntervalMs]);

  // Update farming status
  useEffect(() => {
    if (userProfileInfo?.farming?.isActive) {
      setFarmStartTime(Date.parse(userProfileInfo.farming.startedAt));
    } else {
      setFarmStartTime(null);
    }
  }, [userProfileInfo]);

  // Handlers
  const handleFarming = async () => {
    const isClaimingReward = userProfileInfo.farming?.requiresRewardClaim;
    
    try {
      if (isClaimingReward) {
        showSnackbar('Claiming rewards...', 'info');
        const response = await userApi.claimFarmingReward(userData.id)
        .then(async (response) => {
          setIsActivatingFarmingButton(true);
          setUserProfileInfo(response.data.data);
          setOpenConfetti(true);
          setFarmingProgressPercent(0);
          showSnackbar('Rewards claimed successfully!', 'success');  
          await Promise(resolve => setTimeout(resolve, 500));
        })
        .catch((error) => {
          console.error('Error claiming rewards:', error);
          showSnackbar('Failed to claim rewards', 'error');
        })
      }

      await userApi.activateFarm(userData.id)
      .then(async (response) => {
        showSnackbar("Farming started successfully!", "success");
      })
      .finally(() => {
        setIsActivatingFarmingButton(false);
      });
    } catch (error) {
      console.error('Error with farming:', error);
      showSnackbar(isClaimingReward ? "Failed to claim rewards" : "Failed to start farming", "error");
    }
  };

  const handleTipBoard = useCallback((type) => {
    setHistoryActivityType(type);
    setOpenHistoryPopup(true);
  }, []);

  const handleHistoryActivityType = useCallback((newTab) => {
    setHistoryActivityType(newTab);
  }, []);  

  // Main render
  return (
    <StyledPaper>
      <Header 
        userProfileInfo={userProfileInfo}
        canCheckIn={canCheckIn}
        onCheckInClick={() => setOpenCheckInPopup(true)}
      />

      <ContentContainer>
        <ProgressSection
          userProfileInfo={userProfileInfo}
          progressPercentage={levelProgressPercentage}
          handleOpenLeaguePopup={() => setOpenLeaguesPopup(true)}
        />

        <StatsSection 
          tipMetrics={userProfileInfo?.tipMetrics}
          onTipBoardClick={handleTipBoard}
        />

        <BudgetDisplay 
          treasury={userProfileInfo?.treasury}
          initialBudget={userProfileInfo?.league?.allowance}
          isSmallScreen={isSmallScreen}
        />

        <GlowingCircle>
          <Avatar
            src={FoxiCharacter}
            onClick={() => setOpenNotTapTapPopup(true)}
            sx={{ 
              width: '100%', 
              height: '100%', 
              maxWidth: '35vh', 
              maxHeight: '35vh', 
              borderRadius: '0%', 
              zIndex: 9,
            }}
          />
          
          <ParticleSystem 
            count={25}
            minSize={20}
            maxSize={40}
            spread={450}
            minDuration={5}
            maxDuration={8}
            minDelay={0.25}
            maxDelay={1}
            style={{ left: '45%', top: '40%' }}
          />
          
          <AnimatedCircle
            sx={{
              maxWidth: "42vh",
              maxHeight: "42vh",
              marginBottom: "3vh",
              border: '10px solid #ffffff47',
              borderRadius: '38%',
              filter: "hue-rotate(290deg)",
              zIndex: 1,
            }}
          />
          <AnimatedCircle
            sx={{
              maxWidth: "23vh",
              maxHeight: "23vh",
              borderRadius: "50%",
              boxShadow: "rgb(27 255 140 / 67%) -24vh -12vh 20vh 3.78vh, #c032ff75 12vh 15vh 20vh 7vh, #0013ff69 15vh -16vh 20vh 3.78vh, rgb(0 221 255 / 72%) 10vh -20vh 20vh 3vh, rgb(0 190 255 / 2%) -16vh 11vh 23vh 4.44vh",
              zIndex: 1,
              animation: `${rotate360} 10s linear infinite`,
            }}
          />
          <Shadow />
        </GlowingCircle>

        <Box display="flex" mt="auto" mb={10}>
          <FarmButton 
            farming={userProfileInfo?.farming}
            isActivatingFarmingButton={isActivatingFarmingButton}
            handleFarming={handleFarming}
            remainingNextFarming={remainingNextFarming}
            farmingProgressPercent={farmingProgressPercent}
          />
          
          <StyledButton 
            variant="contained"
            onClick={() => setOpenSendTipPopup(true)}
            sx={{ 
              flex: '1',
              borderRadius: '10rem',
              fontSize: '2vh',
              fontFamily: 'sora, sans-serif', 
              fontWeight: 'bold', 
            }}
          >
            <img 
              width="25" 
              height="25" 
              src="https://img.icons8.com/3d-fluency/94/nui2--v5.png" 
              alt="send-tip" 
              style={{ marginRight: '0.25rem' }} 
            />            
            Send Tip
          </StyledButton>
        </Box>
      </ContentContainer>

      <BottomTabs activeTab={0} />

      <Suspense fallback={<LinearProgress />}>
        {/* Popups */}
        <LeaguesPopup 
          userProfileInfo={userProfileInfo}
          league={userProfileInfo?.league}
          open={openLeaguesPopup}
          onClose={() => setOpenLeaguesPopup(false)}
          showSnackbar={showSnackbar}
          ENDPOINT={ENDPOINT}
        />

        <SendTipPopup 
          userId={userData?.id}
          remainingAllowance={userProfileInfo?.treasury?.allowance || 0}  // Add this prop
          open={openSendTipPopup}
          onClose={() => setOpenSendTipPopup(false)}
          showSnackbar={showSnackbar}
          telApp={telApp}
          ENDPOINT={ENDPOINT}
        />

          <DailyCheckIn 
            userId={userData?.id}
            open={openCheckInPopup}
            onClose={() => setOpenCheckInPopup(false)}
            showSnackbar={showSnackbar}
            ENDPOINT={ENDPOINT}
          />

          <HistoryPopup 
            userId={userData?.id}
            open={openHistoryPopup}
            onClose={() => setOpenHistoryPopup(false)}
            activeTab={historyActivityType}
            setActiveTab={handleHistoryActivityType}
            showSnackbar={showSnackbar}
            ENDPOINT={ENDPOINT}
          />

          <NewLeaguePopup 
            league={userProfileInfo?.league}
            open={openNewLeaguePopup}
            onClose={() => setOpenNewLeaguePopup(false)}
            StyledButton={StyledButton}
            setOpenConfetti={setOpenConfetti}
          />

          <NotTapTapPopup
            open={openNotTapTapPopup}
            onClose={() => setOpenNotTapTapPopup(false)}
            StyledButton={StyledButton}
          />
      </Suspense>

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          opacity: openNotTapTapPopup || openLeaguesPopup || 
                  openSendTipPopup || openCheckInPopup || openHistoryPopup ? 1 : 0,
          display: openNotTapTapPopup || openLeaguesPopup || 
                  openSendTipPopup || openCheckInPopup || openHistoryPopup ? 'flex' : 'none',
          backdropFilter: 'blur(5px)',
          transition: 'opacity 0.3s ease-in-out',
          willChange: 'opacity',
          zIndex: 998,
          alignItems: 'center',
        }}
      />

      <Confetti openConfetti={openConfetti} setOpenConfetti={setOpenConfetti} />
    </StyledPaper>
  );
}

Home.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  setUserProfileInfo: PropTypes.func.isRequired,
  userProfileInfo: PropTypes.shape({
    profile: PropTypes.object,
    treasury: PropTypes.object,
    farming: PropTypes.object,
    tipMetrics: PropTypes.object,
    league: PropTypes.object
  }),
  showSnackbar: PropTypes.func.isRequired,
  telApp: PropTypes.object.isRequired,
  ENDPOINT: PropTypes.string.isRequired,
};

export default React.memo(Home);