import React from 'react';
import { styled, keyframes } from '@mui/system';
import FoxiSearch from '../../assets/images/characters/foxi-search.png';

const fadeIn = keyframes`
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.75;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const SearchingImage = styled('img')({
    position: 'absolute',
    left: '40%',
    top: '40vh',
    width: '100px',
    height: '105px',
    transform: 'translateX(-50%)',
    filter: 'brightness(0) invert(1)',
  });

const Loading = ({ loading = false, customStyle = null }) => {
  return (
    <SearchingImage 
       src={FoxiSearch} 
       alt="Search" 
       loading="lazy"
       sx={{ opacity: loading ? 1 : 0, transition: 'opacity 0.6s ease-in-out', 
         animation: loading && `${fadeIn} 1s ease-in-out infinite`, zIndex: 99, ...customStyle }} 
  />
 );
}

export default Loading;
